import React from 'react'

import OsteopathyDesc from "../components/osteopathyDesc";
import SEO from "../components/seo";
import LayoutLp from "../components/layoutLp";

export default function LpOsteopathy( {location} ) {

    const { pathname } = location;
    const pagetitle = "オステオパシーとは";
    const description = "オステオパシーの由来、どんなことをするのか、誰が施術するのかなどをご説明しています。";
     
    return (

        <LayoutLp isSubPage >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />
        
        <OsteopathyDesc />

        
        </LayoutLp>

    )
}