import {  Link } from "gatsby";
import React from "react";
import PropTypes, { bool } from "prop-types";

import Call from "../static/svg/icon-call.inline.svg";
import Globe from "../static/svg/icon-globe.inline.svg";
import PenLogo from "../static/svg/pen-logo.inline.svg";

function Header( props ) {

  const { langlink, tel, pathTop, isNishiogiOnly} =props;

  return (

      <div className="flex flex-row mx-auto hidden md:flex max-w-screen-2xl ">

        <div className="flex-auto text-right p-2 leading-tight">
          <p className="mb-0">
          <Call className="h-5 w-5 fill-current inline-block mr-0.5 align-text-bottom"/>
          <span className="font-bold text-lg">{tel}</span>
          </p>
        <p className="my-0 text-xs">お電話受付時間：月～土 10:00~18:00
        </p>


        </div>

      </div>



  );
}

Header.defaultProps = {
  langlink: '/404/',
  pathTop: '/',
  isNishiogiOnly: false,
};

Header.propTypes = {
  langlink: PropTypes.string,
  pathTop: PropTypes.string,
  isNishiogiOnly: bool,
};


export default Header;