import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery,graphql, Link } from 'gatsby'

import ExternalLink from "../static/svg/icon-chevron-double-right.inline.svg";

function BlogCards({ targetSlug,  ...props }) {

    const data = useStaticQuery(graphql`
    query mdxQuery2 {
        allMdx(sort: {fields: frontmatter___lastmod, order: DESC}) {
            nodes {
              frontmatter {
                title
                lastmod(formatString: "YYYY年M月D日")
                description
                hero_image_alt
                hero_image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 50
                      transformOptions: {cropFocus: CENTER}
                      aspectRatio: 1
                    )
                  }
                }
              }
              slug
              id
              
            }
          }
      }
    `);

    const posts = data.allMdx.nodes;

    const filteredData = posts.filter(post => {
        const {slug} = post;
        return (slug.includes(targetSlug))
    });

   
    return (
    <div>
        {filteredData.map((node) => (
            <article key={node.id}>

            <div className="rounded border border-c-acc mt-3 mb-3.5">
                <Link to={`/blog/${node.slug}`}>
                    <h2 className="ml-2 mr-0.5 mt-1 mb-0 font-semibold text-base">{node.frontmatter.title}</h2>
                </Link>
                <div className="grid grid-cols-card-sm ">
                    <div className="mt-2 mb-1 ml-1 mr-0.5">
                        <Link to={`/blog/${node.slug}`}>
                            <GatsbyImage
                                image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                                alt={node.frontmatter.hero_image_alt}
                            />
                        </Link>
                    </div>
                    <div>
                        <Link to={`/blog/${node.slug}`}>
                            <p className="text-sm desc-two-rows">{node.frontmatter.description}</p>
                        </Link>
                        <p className="mt-0 mb-1 mr-4 text-right font-semibold text-xs">
                            <Link to={`/blog/${node.slug}`} >
                                    続きを読む
                                    <ExternalLink className="h-4 w-4 mb-0.5 inline-block mr-0.5  " />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            </article>

        )
        )}
        
    </div>
    )
};

BlogCards.defaultProps = {
    targetSlug: "no-target",
  };
  

export default BlogCards;