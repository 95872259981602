import React from "react";
import { StaticImage  } from "gatsby-plugin-image"
import PropTypes from "prop-types";

import BlogCards from "./blogCards";

function OsteopathyDesc ( { isLp } ) {



  return (

      <>
      <h1 className=" my-2 heading-b">オステオパシーとは</h1>
      <p>
       オステオパシーは、本来なら誰でも持っている<span className="marker">「自己回復力」を呼び起こして、不調や病気などからの回復や予防を後押しする</span>ものです。
      </p>  

<p>健康な人でも、ちょっと寝不足が続くと日中も疲れが取れなかったり、集中力が落ちたりします。</p>
<p>いつもは元気な人も風邪をひいて熱がでて咳が止まらなかったら、当然いつものような元気は出せません。</p>
<p>単なる風邪であれば、多くの場合は数日から1週間程度で自らの免疫力により回復していきます。続いた寝不足から解放されて、いつもどおりぐっすり眠るようになれば、自然に健康な身体に戻ります。</p>
<p><span className="marker">自然に健康な状態に戻る人もいれば、慢性的なつらさで悩むかたもいます。</span></p>

<p>その違いは何だと思いますか？</p>

<p>オステオパシーでは、筋肉・関節・筋膜といった<span className="marker">「身体」の異常が「自己回復力が発揮できない」状態を生み出している</span>ことが多い、と考えます。</p>

<p>ちょっとした悪い姿勢のくせや、長時間の無理な姿勢での仕事など、小さなことの積み重ねが、こんなことまで引き起こしてしまいます。</p>

<ul className="list-disc">
<li> <span className="marker">自律神経のアンバランス</span></li>
<li> <span className="marker">内臓機能の低下</span></li>
<li> <span className="marker">リンパの流れ</span>がとどこおる</li>
<li> <span className="marker">血行が悪くなる</span></li>
<li> さらには、<span className="marker">心の状態への悪影響</span></li>
</ul>


<p>オステオパシーでは、脊柱や骨盤のゆがみや、筋肉・筋膜の緊張を取り除きます。  
そして、本来の「自己回復力」を呼び起こして慢性的な不調を減らしていきます。  
</p>
          {isLp ? null :
              <BlogCards targetSlug="session" />
          }

<h2 className="heading-l">頭の先から足のさきまで、身体はひとつ</h2>
<p>オステオパシーでは、「身体はひとつのユニット」と考えます。</p>

<StaticImage
          src="../images/bodies.png"
          alt="身体はひとつのユニット"
          placeholder="blurred"
          layout="constrained"
          width={450}
          className="m-5"
        />



<p className="font-semibold">「座っているだけでも腰が痛いのに、長時間立ちっぱなしなんて無理！」</p>


<p>こんな場合でも、問題が起きているのが腰だけではない場合が多いんです！</p>

<p>
10年前の足首の捻挫が原因で、歩き方にくせがあって、腰に負担がかかっていたことが原因のこともあります。ひょっとして、片方だけ靴底がすり減っていたりしませんか？</p>

<p>痛いからといって、腰だけを検査しても原因は見つかりません。  
<span className="marker">痛い場所から離れたところに本当の原因があることが多いんです。</span>  
足の指先から頭のてっぺんまで、身体はつながっているからです。 </p> 

<p>「頭の先」までを身体全体と考えることは、オステオパシーの特徴の一つです。</p>  
<p>オステオパシーでは、<span className="marker">23個もある頭・顔面の骨は、柔らかく可動性がある</span>と考えます。
頭・顔面の骨を支えている筋膜・筋肉・組織もしっかり検査します。</p>

<p className="mb-6">頭や顔面も含めて検査して、問題が発見されれば、もちろん施術します。</p> 


<h2 className="heading-l">「内臓や免疫」と「骨格や筋肉」の関係</h2>

<p>また、オステオパシーでは、<span className="marker">「&#34;骨格や筋肉&#34;と &#34;ホルモン分泌、内臓や免疫&#34;はお互いに影響しあっている」</span>と考えます。</p>

<p>例えば、筋肉が緊張してリンパの流れを妨げていたら、老廃物が流れずにどんどん溜まってしまい、筋肉の疲れに加えて内臓の機能にも影響がでます。</p>



<p><span className="marker">西洋医学とは違った視点から、内臓やホルモン分泌の問題を検査して施術する</span>ことは、普通の整体とは異なるオステオパシーの大きな特徴のひとつです。</p>

<p className="mb-6">
病院で検査しても何も原因が見つからない場合、オステオパシーの視点からであれば、原因が見つかる可能性は大いにあります。</p>



<h2 className="heading-l">オステオパシー紹介動画</h2>

<p>アメリカンヘルスフロントというテレビ番組の中で、ケネス•ロッシング オステオパシー医師が、オステオパシー医療の有効性について述べています。</p>


  <div className="pb-3/4 relative w-full h-0">
    <iframe
      src="//www.youtube.com/embed/osq0hRd7LZU?autoplay=0"
      title="オステオパシーとは"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      className="absolute w-full h-full left-0 top-0"
    />
  </div>



      </>
      );
}


OsteopathyDesc.defaultProps = {
  isLp: false,
};

OsteopathyDesc.prototype = {
    isLp: PropTypes.bool,
}


export default OsteopathyDesc



